
// JQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Library
require('./bootstrap');
require('slick-slider')

// Components
import('./components/sliders');

// Pages
import('./pages/homepage')